import { colors } from "./colors";

export const theme = {
  colors,
  font: {
    thin: 300,
    regular: 400,
    bold: 800,
  },
  family: {
    main: "Poppins",
  },
  media: {
    desktop: "max-width: 1290px",
    tablet: "min-width: 1290px",
    phone: "max-width: 700px",
    phoneS: "max-width: 500px",
    maxWidth: "1700px",
  },
  size: {
    title: "3rem",
    titleSm: "2.5rem",
    titleXSm: "2rem",
    text: "1rem",
    textB: "1.5rem",
    textM: "1.2rem",
    textSm: "0.8rem",
  },
};
