import React from "react";
import styled from "styled-components";
import {
  rejestr,
  pozyskiwanie,
  emisje,
  fuzje,
  bussines,
} from "./../../img/index";
const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 40px 40px 0px 0px;
`;
const OnasContainer = styled.div`
  //border: 1px solid red; /* RED*/
  //1250 px
  padding: 50px 0;
  border-radius: 40px 40px 0px 0px;
  background-color: ${({ theme }) => theme.colors.blue};
  min-height: 800px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 2000px;
  justify-content: space-around;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  text-align: center;
  // border: 1px solid pink; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.title};
  margin-bottom: 50px;
  font-weight: 600;
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.textB};
    margin: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.white};
  padding: 14px 40px;
  font-size: ${({ theme }) => theme.size.textB};
  border-radius: 40px;
  max-width: 1000px;
  :hover a {
    text-decoration: none;
  }
`;
const Sqr = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  //border: 4px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.textM};
  //text-align: center;
  border-radius: 40px;
  max-width: 950px;
  margin: 20px;
  font-weight: 300;
  transition: 0.2s;
  :hover {
    //color: ${({ theme }) => theme.colors.dark};
    margin-left: 12px;
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.textSm};
    margin: 5px;
  }
  img {
    width: 100px;
    height: 100px;
    margin-right: 30px;
    @media only screen and (${({ theme }) => theme.media.phoneS}) {
      width: 60px;
      height: 60px;
    }
  }
`;
const SqrTitle = styled.div`
  font-size: ${({ theme }) => theme.size.titleXSm};
  margin-bottom: 10px;
  font-weight: 600;
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.textB};
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.text};
  }
`;

function Onas() {
  return (
    <Background>
      <OnasContainer>
        <Title>
          Co jeszcze możemy dla Ciebie zrobić w ramach Navigator Capital Group?
        </Title>
        <Content>
          <a href="https://www.navigatorcapital.pl/business-consulting/">
            <Sqr>
              <img src={bussines.default} alt=" " />

              <div>
                <SqrTitle>Business Consulting</SqrTitle>
                Pomagamy zwiększać skuteczność biznesową firm. Doradzamy przy
                formułowaniu i wdrażaniu strategii oraz zarządzaniu zmianami w
                firmach.
              </div>
            </Sqr>{" "}
          </a>
          <a href="https://www.navigatorcapital.pl/doradztwo-transakcyjne-finansowe/">
            <Sqr>
              <img src={fuzje.default} alt=" " />

              <div>
                <SqrTitle>Fuzje i Przejęcia oraz Corporate Finance</SqrTitle>
                Realizujemy transakcje sprzedaży i przejęć przedsiębiorstw.
              </div>
            </Sqr>
          </a>
          <a href="https://www.navigatorcapital.pl/emisje-akcji-obligacji/">
            <Sqr>
              <img src={emisje.default} alt=" " />

              <div>
                <SqrTitle>Emisje akcji </SqrTitle>
                Pozyskujemy kapitał poprzez emisje akcji na rynku prywatnym i
                publicznym – GPW, NewConnect oraz od funduszy Private Equity i
                Venture Capital.
              </div>
            </Sqr>
          </a>
          <a href="https://www.navigatorcapital.pl/emisje-akcji-obligacji/">
            <Sqr>
              <img src={pozyskiwanie.default} alt=" " />

              <div>
                <SqrTitle>Pozyskiwanie kapitału dłużnego</SqrTitle>
                Przeprowadzamy emisje obligacji, organizujemy finansowanie
                bankowe i mezzanine oraz doradzamy w procesach restrukturyzacji
                zadłużenia.
              </div>
            </Sqr>
          </a>
          <a href="https://www.navigatorcapital.pl/rejestr-akcjonariuszy-dematerializacja-akcji/">
            <Sqr>
              <img src={rejestr.default} alt=" " />

              <div>
                <SqrTitle>Rejestr akcjonariuszy</SqrTitle>
                Nasz Dom Maklerski jest bezdyskusyjnym liderem w zakresie
                przechowywania i rejestrowania papierów wartościowych na polskim
                rynku – oferuje kompleksowe usługi przygotowania się do
                wprowadzanych zmian oraz prowadzenia rejestru akcjonariuszy.
              </div>
            </Sqr>
          </a>
        </Content>
      </OnasContainer>
    </Background>
  );
}

export default Onas;
