//import "./App.css";
import React from "react";
import Navigation from "./components/nav/Navigation";
import Layout from "./components/Layout";
import Main from "./components/main/Main";
import styled from "styled-components";
import System from "./components/system/System";
import Zalety from "./components/zalety/Zalety";
import Kluczowe from "./components/kluczowe/Kluczowe";
import Obsluga from "./components/obsluga/Obsluga";
import Kontakt from "./components/kontakt/Kontakt";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Urzadzenia from "./components/urzadzenia/Urzadzenia";
import Onas from "./components/onas/Onas";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

function App() {
  return (
    <Layout>
      <Container id="container">
        <ReactNotification />
        <Navigation />
        <div id="main"></div>
        <Main />
        <div id="system"></div>
        <System />

        <Zalety />
        <div id="zalety"></div>

        <Kluczowe />
        <div id="kluczowe"></div>

        <Obsluga />
        <div id="obsluga"></div>

        <Urzadzenia />
        <div id="urzadzenia"></div>
        <Onas />
        <div id="onas"></div>

        <Kontakt />
        <div id="kontakt"></div>
      </Container>
    </Layout>
  );
}

export default App;
