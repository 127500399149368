import React from "react";
import styled from "styled-components";
import { poltech } from "./../../img/index";
import Dot from "./../Dot";
import Line from "./../Line";
import Button from "./Button";

const BackgroundT = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.blue};
  justify-content: space-around;
  width: 100%;
`;
const Background = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-around;
  width: 100%;
  border-radius: 40px 40px 0px 0px;
`;
const ObslugaContainer = styled.div`
  //border: 1px solid red; /* RED*/
  //1250 px
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 800px;
  width: 80%;
  flex-wrap: wrap;
  display: flex;
  padding: 50px 0;
  //align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  max-width: 1600px;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    justify-content: center;
    text-align: center;
  }
`;
const Left = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  //border: 1px solid green; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  width: 45%;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 100%;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  //border: 1px solid green; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  width: 100%;
`;
const Image = styled.div`
  //border: 1px solid red; /* RED*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.white};
  width: 50%;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 80%;
  }
  img {
    width: 100%;
    max-width: 1000px;
  }
`;
const Title = styled.div`
  display: flex;
  //border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  font-size: ${({ theme }) => theme.size.title};
  margin-bottom: 50px;
  font-weight: 600;
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.textB};
    margin: 5px;
  }
`;
const Text = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  //border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.size.textM};
  padding: 20px 0px;
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.text};
  }
`;
const TextB = styled.div`
  font-weight: 700;
`;

function Obsluga() {
  return (
    <BackgroundT>
      <Background>
        <ObslugaContainer>
          <Left>
            <Title>
              <Line></Line>
              <div>
                Obsługa głosowań zdalnych oraz hybrydowych na posiedzeniach
              </div>
            </Title>
            <Text>
              <Dot />
              <TextB>
                Realizujemy kompleksową obsługę głosowań za pośrednictwem
                Internetu oraz głosowań hybrydowych, gdzie część uczestników
                znajduje się poza miejscem posiedzenia, a część głosuje
                stacjonarnie.
              </TextB>
            </Text>
          </Left>

          <Image>
            <img alt=" " src={poltech.default} />
          </Image>
          <Bottom>
            <Text>
              <Dot />
              Dzięki zintegrowanemu systemowi telekonferencji, umożliwiamy
              aktywne uczestnictwo w posiedzeniu również osobom głosującym przez
              Internet.
            </Text>
            <Text>
              <Dot />
              <TextB>
                Dla osób uczestniczących stacjonarnie, oferujemy wynajem
                bezprzewodowych terminali dotykowych do obsługi głosowań lub
                umożliwiamy głosowanie na urządzeniach uczestników.
              </TextB>
            </Text>
            <Text>
              <Dot />
              Gwarantujemy sprawne i szybkie działanie systemu niezależnie od
              liczby uczestników posiedzenia.
            </Text>
          </Bottom>
          <a href="#kontakt">
            <Button>Sprawdź naszą ofertę</Button>
          </a>
        </ObslugaContainer>
      </Background>
    </BackgroundT>
  );
}

export default Obsluga;
