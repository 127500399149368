import React from "react";
import styled from "styled-components";
import { supervote, monitorstand } from "./../../img/index";
import Button from "./Button";
import Line from "./../Line";
import YouTube from "react-youtube";
import IframeResizer from "iframe-resizer-react";
import Dot from "./../Dot";

const SystemContainer = styled.div`
  //border: 1px solid red; /* RED*/
  //1250 px
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 800px;
  width: 100%;
  display: flex;
  //align-items: center;
  justify-content: space-around;
  padding: 50px 0;
  max-width: 1700px;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;
const Left = styled.div`
  display: flex;
  flex-wrap: wrap;
  //border: 1px solid green; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  width: 35%;
  min-height: 600px;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 80%;
  }
`;
const Title = styled.div`
  display: flex;
  //border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  font-size: 3.2rem;
  margin-bottom: 50px;
  font-weight: 600;
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: 2rem;
  }
`;
const Text = styled.h3`
  display: flex;
  /*border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.1rem;
`;
const TextB = styled.h2`
  font-weight: 700;
  font-size: 1.1rem;
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  //border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  width: 45%;
  img {
    width: 100%;
  }
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 80%;
    padding-top: 10%;
  }
  iframe {
    border: 20px solid black;
    overflow: hidden;
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    max-width: 1280px;
    height: 500px;
    @media only screen and (${({ theme }) => theme.media.desktop}) {
      width: 100%;
      height: 600px;
    }
    @media only screen and (${({ theme }) => theme.media.phone}) {
      height: 300px;
    }
  }
`;

const Stand = styled.div`
  //border: 1px solid red; /* RED*/
  //overflow: hidden;
  width: 100%;
  max-width: 1280px;
  //margin-bottom: 50px;
  img {
    width: 100%;
  }
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 100%;
  }
`;

function System() {
  return (
    <>
      <SystemContainer>
        <Left>
          <Title>
            <Line></Line>
            <div>Nasz system do głosowania</div>
            <h2 hidden>
              eZgromadzenia to sprawdzony system do obsługi głosowań na
              posiedzeniach.
            </h2>
            <h2 hidden>
              Zarządzany w całości przez przeglądarkę internetową, nie wymaga
              zewnętrznego oprogramowania typu PowerPoint etc.
            </h2>
          </Title>
          <Text>
            <Dot />
            <TextB>
              eZgromadzenia to sprawdzony system do obsługi głosowań na
              posiedzeniach.
            </TextB>
          </Text>
          <Text>
            <Dot />
            Zarządzany w całości przez przeglądarkę internetową, nie wymaga
            zewnętrznego oprogramowania typu PowerPoint etc.
          </Text>
          <Text>
            <Dot />
            <TextB>
              Umożliwia przygotowywanie porządków obrad, planowanie głosowań,
              załączanie dokumentów dla uczestników (jak projekty uchwał), a
              także zdalne uczestnictwo w obradach i prowadzenie dyskusji
              podczas posiedzenia za pomocą wbudowanego komunikatora.
            </TextB>
          </Text>
          <Text>
            <Dot />
            Zapewnia możliwość integracji z systemami transmisji - automatycznie
            nakładanie wyników głosowań na obraz video.
          </Text>
          <a href="#kontakt">
            <Button>Sprawdź naszą ofertę</Button>
          </a>
        </Left>
        <Image>
          <iframe
            src="https://www.youtube.com/embed/Uv1i8jLBLqg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <Stand>
            <img alt=" " src={monitorstand.default} />
          </Stand>
        </Image>
      </SystemContainer>
    </>
  );
}

export default System;
