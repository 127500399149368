import React from "react";
import styled from "styled-components";
import { mainpic } from "./../../img/index";
import { rectangle } from "./../../img/index";
const Background = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.blue};
  justify-content: space-around;
  width: 100%;
`;
const MainContainer = styled.div`
  /*border: 1px solid red; /* RED*/
  //1250 px
  background-color: ${({ theme }) => theme.colors.blue};
  //min-height: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 0;
  max-width: 1800px;
`;
const Text = styled.div`
  //border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.title};

  width: 35%;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 90%;
    text-align: center;
    font-size: ${({ theme }) => theme.size.title};
  }
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
  h1 {
    padding-bottom: 50px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.size.title};
    @media only screen and (${({ theme }) => theme.media.desktop}) {
      text-align: center;
      font-size: ${({ theme }) => theme.size.title};
    }
    @media only screen and (${({ theme }) => theme.media.phone}) {
      font-size: ${({ theme }) => theme.size.titleSm};
    }
  }
`;
const Image = styled.div`
  //border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  width: 50%;
  img {
    width: 100%;
  }
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 80%;
    padding-top: 40px;
  }
`;
const Button = styled.a`
  border: 3px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  padding: 14px 40px;
  font-size: ${({ theme }) => theme.size.textB};
  border-radius: 40px;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: none;
  }
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    display: none;
  }
  :focus {
    outline: none;
    text-decoration: none;
  }
`;
const Rectangle = styled.img`
  //border: 1px solid red; /* RED*/

  width: 100%;
`;
function Main() {
  return (
    <>
      <Background>
        <MainContainer>
          <Text>
            <div>
              <h1>Obsługa głosowań na posiedzeniach najnowszej generacji</h1>
            </div>

            <Button href="#kontakt">Sprawdź</Button>
          </Text>

          <Image>
            <img alt=" " src={mainpic.default} />
          </Image>
        </MainContainer>
      </Background>
      <Rectangle src={rectangle.default} />
    </>
  );
}

export default Main;
