import React from "react";
import { store } from "react-notifications-component";
import styled from "styled-components";
import emailjs from "emailjs-com";
function ContactForm() {
  /* Here’s the juicy bit for posting the form submission */
  function call(e) {
    store.addNotification({
      title: "Wiadomość została wysłana.",
      message: " ",
      type: "success",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "default_service",
        "template_kdgknxx",
        e.target,
        "user_Sjd7YnzuYgob89cFmWqbR"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    call();
    e.target.reset();
  }

  return (
    <>
      <form onSubmit={sendEmail}>
        <label>
          Imię i nazwisko: <input type="text" name="imie" required />
        </label>
        <label>
          Firma (opcjonalne): <input type="text" name="firma" />
        </label>
        <label>
          Email: <input type="email" name="email" required />
        </label>
        <label>
          Telefon: <input type="text" name="telefon" required />
        </label>
        <label>
          Temat wiadomości: <input type="text" name="temat" required />
        </label>
        <label>
          Wiadomość (opcjonalne):
          <textarea type="text" name="wiadomosc" />
        </label>
        <label>
          <input type="checkbox" name="emailcheckbox" /> Wyrażam zgodę na
          otrzymywanie od Grupy Navigator Capital informacji handlowych na
          podany przeze mnie adres e-mail
        </label>
        <label>
          <input type="checkbox" name="telcheckbox" /> Wyrażam zgodę na
          otrzymywanie od Grupy Navigator Capital informacji handlowych z
          wykorzystaniem podanego przeze mnie numeru telefonu
        </label>
        <button type="submit">Wyslij</button>
        <r1>
          Zgoda jest dobrowolna i można ją cofnąć w każdym momencie. Cofnięcie
          zgody nie wpłynie na zgodność z prawem przetwarzania, którego dokonano
          na podstawie zgody przed jej wycofaniem. Więcej informacji o sposobie
          przetwarzania danych przez administratora danych osobowych oraz o
          Państwa uprawnieniach znajdziecie Państwo na stronie:
          <a href="https://www.navigatorcapital.pl/klauzula-informacyjna/">
            https://www.navigatorcapital.pl/klauzula-informacyjna/
          </a>
        </r1>
      </form>
    </>
  );
}

const KontaktFormContainer = styled.div`
  //border: 1px solid red; /* RED*/
  //1250 px
  //pawel.stadnik@navigatorcapital.pl
  padding: 50px 0;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  min-height: 600px;
  max-width: 600px;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 90%;
  }
  r1 {
    color: #d4d4d4;
    font-size: 12px;
  }
  div {
    margin-left: 25px;
    font-size: 10px;
  }
  label {
    width: 100%;
  }
  input,
  select,
  textarea {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.dark};
    padding: 12px 20px;
    margin: 8px 0;
    color: ${({ theme }) => theme.colors.white};
    display: inline-block;
    border: 4px solid ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    box-sizing: border-box;
  }
  input[type="checkbox"] {
    width: 20px;
  }
  textarea {
    height: 200px;
  }

  button {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 600;
    font-size: 1.2rem;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;

function KontaktForm() {
  return (
    <KontaktFormContainer>
      <ContactForm />
    </KontaktFormContainer>
  );
}

export default KontaktForm;
