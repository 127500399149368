export const logowhite = require("./logowhite.png");
export const poltech = require("./poltech.png");
export const mainpic = require("./mainpic.png");
export const minilogo = require("./minilogo.png");
export const glosowan = require("./glosowan.png");
export const monitorstand = require("./monitorstand.png");
export const myguy = require("./myguy.png");
export const rectangle = require("./rectangle.svg");
export const world = require("./world.svg");
export const menu = require("./menu.svg");
export const supervote = require("./aio_supervote.png");
export const chat = require("./icons/chat.svg");
export const checkbox = require("./icons/checkbox.svg");
export const envelope = require("./icons/envelope.svg");
export const eye = require("./icons/eye.svg");
export const file = require("./icons/file.svg");
export const laptop = require("./icons/laptop.svg");
export const newa = require("./icons/new.svg");
export const film = require("./icons/film.svg");
export const notebook = require("./icons/notebook.svg");
export const personadd = require("./icons/person-add.svg");
export const settings = require("./icons/settings.svg");
export const suitcase = require("./icons/suitcase.svg");
export const language = require("./icons/language.svg");
export const bussines = require("./iconsOnas/12.png");
export const fuzje = require("./iconsOnas/13.png");
export const emisje = require("./iconsOnas/14.png");
export const pozyskiwanie = require("./iconsOnas/15.png");
export const rejestr = require("./iconsOnas/16.png");
export const phone = require("./icons/phone2.png");
export const mail = require("./icons/mail2.png");
