import styled from "styled-components";

const Dot = styled.div`
  border: 10px solid ${({ theme }) => theme.colors.blue}; /* RED*/
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 40px;
  width: 15px;
  height: 15px;
  margin-right: 20px;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    display: none;
  }
`;

export default Dot;
