import React from "react";
import styled from "styled-components";
import {
  chat,
  envelope,
  eye,
  file,
  laptop,
  newa,
  notebook,
  personadd,
  settings,
  suitcase,
  film,
} from "./../../img/index";
const BackgroundT = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.dark};
  justify-content: space-around;
  width: 100%;
`;

const Background = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.blue};
  justify-content: space-around;
  width: 100%;
  border-radius: 40px 40px 0px 0px;
`;
const KluczoweContainer = styled.div`
  //border: 1px solid red; /* RED*/
  //1250 px
  padding: 50px 0;
  min-height: 800px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 50px;
  max-width: 1800px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  text-align: center;
  // border: 1px solid pink; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.title};
  margin-bottom: 50px;
  font-weight: 600;
  div {
    color: ${({ theme }) => theme.colors.blue};
  }
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.textB};
    margin: 5px;
  }
`;

const Content = styled.div`
  //border: 1px solid pink; /* RED*/
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 14px 40px;
  font-size: ${({ theme }) => theme.size.textB};
  border-radius: 40px;
  width: 100%;
`;
const Sqr = styled.div`
  //border: 1px solid pink; /* RED*/
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  padding: 20px 20px;
  width: 300px;
  margin: 30px;
  font-weight: 300;

  @media only screen and (${({ theme }) => theme.media.phone}) {
    width: 100%;
    margin: 0px;
    justify-content: flex-start;
  }
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    //width: 100%;
    margin: 5px;
  }
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10px;
  //border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.text};
  font-weight: 400;
`;
const Icon = styled.img`
  //border: 1px solid red; /* RED*/

  min-width: 45px;
  max-height: 100px;
`;

function Kluczowe() {
  return (
    <BackgroundT>
      <Background>
        <KluczoweContainer>
          <Title>Kluczowe funkcjonalności systemu eZgromadzenia</Title>
          <Content>
            <Sqr>
              <Icon src={laptop.default} />
              <Text>
                Dostęp do platformy z każdego urządzenia za pomocą przeglądarki
                lub aplikacji
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={envelope.default} />
              <Text>
                Wysyłanie zaproszeń na zgromadzenia poprzez wiadomość mailową
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={notebook.default} />
              <Text>
                Generowanie protokołów oraz listy obecności (automatyczne
                obliczanie kworum)
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={eye.default} />
              <Text>Obsługa głosowań jawnych oraz tajnych</Text>
            </Sqr>
            <Sqr>
              <Icon src={personadd.default} />
              <Text>
                Możliwość masowego wgrywania danych uczestników do systemu
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={film.default} />
              <Text>
                Możliwość integracji z systemami transmisji audio-video
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={chat.default} />
              <Text>
                Wbudowany moduł komunikatora tekstowego pozwalający na
                dwustronną komunikację
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={suitcase.default} />
              <Text>Łatwe dodawanie oraz obsługa pełnomocnictw</Text>
            </Sqr>
            <Sqr>
              <Icon src={file.default} />
              <Text>
                Publikacja porządku obrad i dokumentów przed rozpoczęciem
                zgromadzenia
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={newa.default} />
              <Text>
                Edycja porządku obrad, wgrywanie nowych dokumentów w trakcie
                obrad
              </Text>
            </Sqr>
            <Sqr>
              <Icon src={settings.default} />
              <Text>Interfejs dostępny w języku polskim i angielskim</Text>
            </Sqr>
            <Sqr>
              <Text>… i wiele innych</Text>
            </Sqr>
          </Content>
        </KluczoweContainer>
      </Background>
    </BackgroundT>
  );
}

export default Kluczowe;
