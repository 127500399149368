import React from "react";
import styled from "styled-components";

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 40px 40px 0px 0px;
`;
const ZaletyContainer = styled.div`
  //border: 1px solid red; /* RED*/
  //1250 px
  padding: 50px 0;
  border-radius: 40px 40px 0px 0px;
  background-color: ${({ theme }) => theme.colors.dark};
  min-height: 800px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 2000px;
  justify-content: space-around;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  text-align: center;
  // border: 1px solid pink; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.title};
  margin-bottom: 50px;
  font-weight: 600;
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.textB};
    margin: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 14px 40px;
  font-size: ${({ theme }) => theme.size.textB};
  border-radius: 40px;
  width: 100%;
`;
const Sqr = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  border: 4px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  padding: 40px 20px;
  font-size: ${({ theme }) => theme.size.textM};
  text-align: center;
  border-radius: 40px;
  width: 450px;
  margin: 30px;
  font-weight: 300;
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.text};
    margin: 5px;
  }
`;
const SqrTitle = styled.div`
  font-size: ${({ theme }) => theme.size.titleXSm};
  margin-bottom: 20px;
  font-weight: 600;
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.textB};
    margin: 5px;
  }
`;

function Zalety() {
  return (
    <Background>
      <ZaletyContainer>
        <Title>Zalety systemu eZgromadzenia do obsługi głosowań</Title>
        <Content>
          <Sqr>
            <SqrTitle>Wygoda uczestnictwa</SqrTitle>
            Brak konieczności podróżowania do miejsca spotkania kluczowych
            decydentów.
          </Sqr>
          <Sqr>
            <SqrTitle>Bezpieczeństwo</SqrTitle>
            Ograniczenie ryzyka zakażenia w czasach pandemii.
          </Sqr>
          <Sqr>
            <SqrTitle>Oszczędność</SqrTitle>
            Brak kosztów związanych z organizacją spotkania w miejscu fizycznym
            (wynajem sali, poczęstunek, diety, noclegi, koszty transportu,
            wynajem sprzętu do głosowania).
          </Sqr>
          <Sqr>
            <SqrTitle>Dostęp do informacji</SqrTitle>
            Elektroniczne repozytorium dokumentów (np. uchwał, protokołów),
            wyników głosowań i nagrań posiedzeń w jednym miejscu on-line 24h.{" "}
          </Sqr>
          <Sqr>
            <SqrTitle>Łatwość organizacji</SqrTitle>
            Wzorce agend, uchwał, zaproszeń, dane kontaktowe uczestników,
            komunikowanie zmian itp.{" "}
          </Sqr>
        </Content>
      </ZaletyContainer>
    </Background>
  );
}

export default Zalety;
