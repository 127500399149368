import React from "react";
import styled from "styled-components";
import { glosowan } from "./../../img/index";
import Line from "./../Line";
import Dot from "./../Dot";

const UrzadzeniaContainer = styled.div`
  //border: 1px solid red; /* RED*/
  background-color: ${({ theme }) => theme.colors.white};
  width: 80%;
  flex-wrap: wrap;
  display: flex;
  padding: 50px 0;
  justify-content: space-between;
  padding-top: 50px;
  max-width: 1600px;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    justify-content: center;
    text-align: center;
  }
`;
const Left = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  //border: 1px solid green; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  width: 45%;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 100%;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  //border: 1px solid green; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  width: 100%;
`;
const Image = styled.div`
  //border: 1px solid red; /* RED*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.white};
  width: 50%;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 80%;
  }
  img {
    width: 100%;
    max-width: 1000px;
  }
`;
const Title = styled.div`
  display: flex;
  //border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.blue};
  font-size: ${({ theme }) => theme.size.title};
  margin-bottom: 50px;
  font-weight: 600;
  justify-content: center;
  @media only screen and (${({ theme }) => theme.media.desktop}) {
    width: 100%;
  }
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.textB};
    margin: 5px;
  }
`;
const Text = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // border: 1px solid red; /* RED*/
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.size.textM};
  padding: 20px 0px;
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    font-size: ${({ theme }) => theme.size.text};
    margin: 5px;
  }
`;
const TextB = styled.div`
  font-weight: 700;
`;

function Urzadzenia() {
  return (
    <>
      <UrzadzeniaContainer>
        <Left>
          <Title>
            <Line></Line>
            <div>Nasze urządzenia do głosowania</div>
          </Title>
          <Text>
            <Dot />
            <TextB>
              W ramach współpracy możemy zapewnić do 500 bezprzewodowych
              terminali do głosowania z aplikacją eZgromadzenia oraz do 100
              tabletów.
            </TextB>
          </Text>
          <Text>
            <Dot />
            Uczestnicy mogą również głosować na dowolnym urządzeniu z dostępem
            do sieci Internet: laptopie, tablecie czy smartfonie.
          </Text>
        </Left>

        <Image>
          <img alt=" " src={glosowan.default} />
        </Image>
        <Bottom>
          <Text>
            <Dot />
            <TextB>
              Dzięki zastosowaniu nowoczesnego systemu oraz ekranu urządzenia z
              łatwością przeprowadzisz wybory lub inne wymagające głosowania.
            </TextB>
          </Text>
          <Text>
            <Dot />
            Poza głosowaniami, uczestnicy mogą również przeglądać porządek
            obrad, dodane załączniki, a także zadawać pytania za pomocą
            wbudowanego komunikatora.
          </Text>
          <Text>
            <Dot />
            Przyciski do głosowania pojawiają się automatycznie w momencie
            wywołania głosowania przez operatora.
          </Text>
        </Bottom>
      </UrzadzeniaContainer>
    </>
  );
}

export default Urzadzenia;
