import React from "react";
import KontaktForm from "./KontaktForm";
import styled from "styled-components";
import { myguy } from "./../../img/index";
import { phone } from "./../../img/index";
import { mail } from "./../../img/index";

const BackgroundT = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.blue};
  justify-content: space-around;
  width: 100%;
`;
const Background = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.dark};
  justify-content: space-around;
  width: 100%;
  border-radius: 40px 40px 0px 0px;
`;
const KontaktContainer = styled.div`
  //border: 1px solid red; /* RED*/
  //1250 px
  padding: 50px 0;
  border-radius: 40px 40px 0px 0px;
  background-color: ${({ theme }) => theme.colors.dark};
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 2000px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  // border: 1px solid pink; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.title};

  margin-bottom: 50px;
  font-weight: 600;
  div {
    color: ${({ theme }) => theme.colors.blue};
  }
  @media only screen and (${({ theme }) => theme.media.phone}) {
    margin-bottom: 10px;
  }
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.titleSm};
  }
`;
const Info = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  //text-align: center;
  // border: 1px solid pink; /* RED*/
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.textB};

  margin-bottom: 30px;
  font-weight: 500;
  div {
    color: ${({ theme }) => theme.colors.blue};
  }
  @media only screen and (${({ theme }) => theme.media.phone}) {
    margin-bottom: 10px;
  }
  @media only screen and (${({ theme }) => theme.media.phone}) {
    font-size: ${({ theme }) => theme.size.text};
  }
`;
const Image = styled.div`
  //border: 1px solid red; /* RED*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.white};
  width: 30%;
  max-width: 500px;

  @media only screen and (${({ theme }) => theme.media.desktop}) {
    display: none;
  }
  img {
    max-height: 900px;
    //width: 100%;
    //height: 100%;
  }
`;
const Image2 = styled.div`
  //border: 1px solid red; /* RED*/
  display: flex;
  align-items: center;
  height: 34px;
  justify-content: flex-start;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.white};

  max-width: 500px;

  @media only screen and (${({ theme }) => theme.media.desktop}) {
    height: 25px;
  }
  img {
    max-height: 900px;
    //width: 100%;
    height: 100%;
  }
`;
function Kontakt() {
  return (
    <BackgroundT>
      <Background>
        <KontaktContainer>
          <div id="kontakt"></div>
          <Title>Kontakt</Title>
          <Info>
            <Image2>
              <img alt=" " src={mail.default} />
            </Image2>
            biuro@navigatorcapital.pl
          </Info>
          <Info>
            <Image2>
              <img alt=" " src={phone.default} />
            </Image2>
            +48 22 630 83 33
          </Info>
          <KontaktForm />
          <Image>
            <img alt=" " src={myguy.default} />
          </Image>
        </KontaktContainer>
      </Background>
    </BackgroundT>
  );
}

export default Kontakt;
