import React from "react";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";
import { menu as Cart } from "./../../img/index";
import { language } from "./../../img/index";
import Button from "./Button";
import regulamin from "./../../pdf/Regulamin_uslug_Navigator.pdf";
const Menun = styled.div`
  color: black;
  .bm-burger-button {
    position: fixed;
    width: 40px;
    height: 40px;
    right: 40px;
    top: 20px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: ${({ theme }) => theme.colors.white};
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${({ theme }) => theme.colors.blue};
    padding: 2.5em 1.5em 0;
    font-size: 2rem;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: ${({ theme }) => theme.colors.white};
    padding: 0.2em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    color: ${({ theme }) => theme.colors.white};
    margin: 20px 20px;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const BurgerMenu = () => {
  return (
    <Menun>
      <Menu right customBurgerIcon={<img alt="" src={Cart.default} />}>
        <Button href="#system">Nasz system</Button>
        <Button href="#zalety">Zalety systemu</Button>
        <Button href="#kluczowe">Funkcjonalności</Button>
        <Button href="#obsluga">Wydarzenia hybrydowe</Button>
        <Button href="#urzadzenia">Urządzenia</Button>
        <Button href="#onas">O nas</Button>
        <Button href="#kontakt">Kontakt</Button>
        <Button href={regulamin} download>
          Regulamin
        </Button>
        <Button href="https://www.navigatorcapital.pl/business-consulting/">
          <img src={language.default} alt=" " />
        </Button>
      </Menu>
    </Menun>
  );
};
export default BurgerMenu;
