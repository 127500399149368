import React from "react";
import styled from "styled-components";
import Button from "./Button";
import { logowhite, language } from "./../../img/index";
import BurgerMenu from "./BurgerMenu";
import regulamin from "./../../pdf/Regulamin_uslug_Navigator.pdf";
const NavBarContainer = styled.div`
  /*border: 1px solid red; /* RED*/
  //1250 px
  background-color: ${({ theme }) => theme.colors.blue};
  min-height: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  padding: 15px 20px;
  transition: top 0.5s;
  top: 0;
  z-index: 80;
  @media only screen and (${({ theme }) => theme.media.phoneS}) {
    display: none;
  }
`;
const Logo = styled.img`
  //border: 1px solid red; /* RED*/
  width: 350px;
`;
const Menu = styled.div`
  //border: 1px solid red; /* RED*/
  display: flex;
  @media only screen and (max-width: 1400px) {
    display: none;
  }

  top: 0;
`;
const BurgerMenuu = styled.div`
  //border: 1px solid red; /* RED*/

  @media only screen and (min-width: 1400px) {
    display: none;
  }

  z-index: 84;
`;

function Navigation() {
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-100px";
    }
    prevScrollpos = currentScrollPos;
  };
  return (
    <>
      <NavBarContainer id="navbar">
        <a href="https://www.navigatorcapital.pl/business-consulting/">
          <Logo src={logowhite.default} />
        </a>
        <Menu>
          <Button href="#system">Nasz system</Button>
          <Button href="#zalety">Zalety systemu</Button>
          <Button href="#kluczowe">Funkcjonalności</Button>
          <Button href="#obsluga">Wydarzenia hybrydowe</Button>
          <Button href="#urzadzenia">Urządzenia</Button>
          <Button href="#onas">O nas</Button>
          <Button href="#kontakt">Kontakt</Button>
          <Button href={regulamin} download>
            Regulamin
          </Button>
          <Button href="https://www.navigatorcapital.pl/business-consulting/">
            <img src={language.default} alt=" " />
          </Button>
        </Menu>
      </NavBarContainer>
      <BurgerMenuu>
        <BurgerMenu />
      </BurgerMenuu>
    </>
  );
}

export default Navigation;
