import styled from "styled-components";

const Button = styled.a`
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.size.text};
  padding: 10px 10px;
  margin: 2px;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  cursor: pointer;
  :hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }
  :focus {
    outline: none;
  }
`;

export default Button;
