import styled from "styled-components";

const Button = styled.button`
  border: 3px solid ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue};
  padding: 10px 40px;
  font-size: ${({ theme }) => theme.size.text};
  font-weight: 700;
  border-radius: 40px;
  margin-top: 70px;
  outline: none;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
  :focus {
    outline: none;
  }
`;

export default Button;
